import React from 'react';
import css from './SectionBuyRent.module.css';

import ComingSoonImage from './images/comingSoon.png';
import ShopNowImage from './images/shopNow.png';
import { NamedLink } from '../../../../components';


const SectionBuyRent = () => {

  return (
    <div className={css.root}>
      <h1 className={css.promoText}>Find your winning piece</h1>

      <div className={css.imagesWrapper}>

        <div className={css.imageWrapper}>
          <img className={css.image} src={ShopNowImage} />
          <NamedLink
            name="SearchPage">
            {'> Buy a winning piece'}
          </NamedLink>
        </div>

        <div className={css.imageWrapper}>
          <img className={css.image} src={ComingSoonImage} />
        </div>
      </div>
    </div>
  );
};

export default SectionBuyRent;
