import React from 'react';
import FasterImage from './images/faster-image.png';
import ProfitImage from './images/profit-image.png';
import SavingsImage from './images/savings-image.png';
import SustainabilityImage from './images/sustainability-image.png';
import css from './SectionBenefits.module.css';

const SectionBenefits = () => {

  return (
    <div className={css.wrapper}>

      <div className={css.benefitContainer}>
        <img className={css.benefitImage} src={SavingsImage} />
        <p className={css.benefitName}>
          Smart Savings
        </p>
        <p className={css.benefitInfo}>
          Prepare for your competition without the hefty price tag. Buy what you love at prices that feel like a win!
        </p>
      </div>

      <div className={css.benefitContainer}>
        <img className={css.benefitImage} src={FasterImage} />
        <p className={css.benefitName}>
          Faster and Easier
        </p>
        <p className={css.benefitInfo}>
          Connect with competitors like you and use filters to find outfits that fit without the long wait time.
        </p>
      </div>

      <div className={css.benefitContainer}>
        <img className={css.benefitImage} src={ProfitImage} />
        <p className={css.benefitName}>
          Profit from Your Hard Work
        </p>
        <p className={css.benefitInfo}>
          After your big day, pass on your success (and your outfit) to the next star. Easy listing, quick sales, secure payments.
        </p>
      </div>

      <div className={css.benefitContainer}>
        <img className={css.benefitImage} src={SustainabilityImage} />
        <p className={css.benefitName}>
          Sustainability Wins
        </p>
        <p className={css.benefitInfo}>
          Compete with a conscience. Help the planet by joining it on the cycle of reusing stunning competition gear.
        </p>
      </div>

    </div >
  );
};

export default SectionBenefits;
