import React from 'react';
import loadable from '@loadable/component';

import { bool, object } from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';

import { camelize } from '../../util/string';
import { propTypes } from '../../util/types';

import FallbackPage from './FallbackPage';
import { ASSET_NAME } from './LandingPage.duck';

import HeroSection from './components/HeroSection/HeroSection';
import SectionBuyRent from './components/SectionBuyRent/SectionBuyRent';
import SectionOurMission from './components/SectionOurMission/SectionOurMission';
import SectionCategories from './components/SectionCategories/SectionCategories';
import SectionBikiniAthlets from './components/SectionBikiniAthlets/SectionBikiniAthlets';
import SectionWelcome from './components/SectionWelcome/SectionWelcome';
import SectionBenefits from './components/SectionBenefits/SectionBenefits';
import SectionShine from './components/SectionShine/SectionShine';
import SectionSize from './components/SectionSize/SectionSize';
import SectionPerfectFit from './components/SectionPerfectFit/SectionPerfectFit';
import SectionImagine from './components/SectionImagine/SectionImagine';
import SectionHowItWorks from './components/SectionHowItWorks/SectionHowItWorks';
import SectionPopularLocations from './components/SectionPopularLocations/SectionPopularLocations';

const PageBuilder = loadable(() =>
  import(/* webpackChunkName: "PageBuilder" */ '../PageBuilder/PageBuilder')
);

export const LandingPageComponent = props => {
  const { pageAssetsData, inProgress, error } = props;

  return (
    <PageBuilder
      pageAssetsData={pageAssetsData?.[camelize(ASSET_NAME)]?.data}
      inProgress={inProgress}
      error={error}
      fallbackPage={<FallbackPage error={error} />}
      firstSection={
        <>
          <HeroSection />
          <SectionOurMission />
          <SectionCategories />
          <SectionBikiniAthlets />
          <SectionWelcome />
          <SectionBenefits />
          <SectionPerfectFit />
          <SectionBuyRent />
          {/* <SectionShine /> */}
          {/* <SectionSize /> */}
          {/* <SectionImagine /> */}
          <SectionHowItWorks />
          {/* <SectionPopularLocations /> */}
        </>
      }
    />
  );
};

LandingPageComponent.propTypes = {
  pageAssetsData: object,
  inProgress: bool,
  error: propTypes.error,
};

const mapStateToProps = state => {
  const { pageAssetsData, inProgress, error } = state.hostedAssets || {};
  return { pageAssetsData, inProgress, error };
};

// Note: it is important that the withRouter HOC is **outside** the
// connect HOC, otherwise React Router won't rerender any Route
// components since connect implements a shouldComponentUpdate
// lifecycle hook.
//
// See: https://github.com/ReactTraining/react-router/issues/4671
const LandingPage = compose(connect(mapStateToProps))(LandingPageComponent);

export default LandingPage;
