import React from 'react';
import css from './SectionBikiniAthlets.module.css';

const SectionBikiniAthlets = () => {

  return (
    <div className={css.root}>
      <div className={css.wrapper}>
        <p className={css.title}>We're for bikini athletes who:</p>

        <ul>
          <li>
            <p className={css.info}>
              • Want to make the most of their comp prep journey, book their dream photo shoots and capture all the moments of their evolution into the stage
            </p>
          </li>
          <li>
            <p className={css.info}>
              • Don't want to feel restricted by budgets and price tags for their perfect gown or custom couture bikini
            </p>
          </li>

          <li>
            <p className={css.info}>
              • Will stop at nothing to take home the win or even win a Pro Card
            </p>
          </li>

          <li>
            <p className={css.info}>
              • Won't let their finances be an obstacle to their next show
            </p>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default SectionBikiniAthlets;
