import React from 'react';
import css from './SectionWelcome.module.css';

const SectionWelcome = () => {

  return (
    <div className={css.wrapper}>
      <p className={css.title}>WELCOME TO THE COMP CLOSET</p>

      <p className={css.info}>
        Ready to wear, pre-loved stage wear
      </p>
    </div>
  );
};

export default SectionWelcome;
