import React from 'react';
import css from './SectionHowItWorks.module.css';
import { NamedLink } from '../../../../components';

const SectionHowItWorks = () => {

  return (
    <div className={css.root}>

      <p className={css.mainTitle}>  How it works:</p>

      <div className={css.wrapper}>
        <div className={css.container}>
          <p className={css.title}>
            Post a new listing
          </p>
          <p className={css.info}>
            Snap a photo, set your price and fill in the details for your competition outfits. Accept payments from customers and receive money to your bank account.
          </p>

          <NamedLink
            name="NewListingPage">
            {'> Post a new listing'}
          </NamedLink>
        </div>

        <div className={css.container}>
          <p className={css.title}>
            Discover with Precision
          </p>
          <p className={css.info}>
            Search by location or keyword. Filter by size, colour, designer, price, location and availability. View photos and listing details.
          </p>

          <NamedLink
            name="SearchPage">
            {'> Search listings'}
          </NamedLink>
        </div>

        <div className={css.container}>
          <p className={css.title}>
            Checkout with Confidence
          </p>
          <p className={css.info}>
            Select your stage wear, use our encrypted checkout, and you can complete your purchase with peace of mind, free to focus on your preparation and performance.
          </p>

          <NamedLink
            name="SearchPage">
            {'> Buy something'}
          </NamedLink>
        </div>
      </div>
    </div >
  );
};

export default SectionHowItWorks;
