import React from 'react';
import css from './HeroSection.module.css';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { useRouteConfiguration } from '../../../../context/routeConfigurationContext';
import { createResourceLocatorString } from '../../../../util/routes';
import { PrimaryButton, SecondaryButton } from '../../../../components';

const HeroSection = () => {
  const history = useHistory();
  const routeConfiguration = useRouteConfiguration();

  const SeachLinkButton = ({ label, searchParams }) => {
    const handleClick = () => {
      history.push(createResourceLocatorString('SearchPage', routeConfiguration, {}, searchParams));
    };
    return (
      <div className={css.searchLinkButton} onClick={handleClick}>
        {label}
      </div>
    );
  };

  const handleClick = page => {
    if (page === 'ShopPage') {
      history.push('/s');
    } else //if (page === 'SellPage)
    {
      history.push('/l/new');
    }
    // } else {
    //   console.error('Unknown page', page);
    // }
  };

  return (
    <div className={css.wrapper}>
      <div className={css.contentWrapper}>
        <p className={css.promoText}>It's your time to shine!</p>
        <p className={css.promoSubtitle}>Wear a winning piece without the price tag.</p>
        <div className={css.buttonGroup}>
          <PrimaryButton
            onClick={() => handleClick('ShopPage')}>Shop a winning piece
          </PrimaryButton>
          <SecondaryButton
            onClick={() => handleClick('SellPage')}>Sell your items
          </SecondaryButton>
        </div>
      </div>
      <div className={css.imageWrapper}>
      </div>
    </div>
  );
};

export default HeroSection;
