import React from 'react';
import css from './SectionPerfectFit.module.css';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { useRouteConfiguration } from '../../../../context/routeConfigurationContext';
import { PrimaryButton } from '../../../../components';

const SectionPerfectFit = () => {

  const history = useHistory();
  const routeConfiguration = useRouteConfiguration();

  const handleClick = page => {
    if (page === 'SearchPage') {
      history.push('/s');
    } else //if (page === 'SellPage)
    {
      history.push('/l/new');
    }
    // } else {
    //   console.error('Unknown page', page);
    // }
  };

  return (
    <div className={css.wrapper}>

      <div className={css.imageWrapper}>
        {/* <img src={myImage} alt="SectionPerfectFit" /> */}
      </div>

      <div className={css.contentWrapper}>
        <h2 className={css.promoText}>
          We've got the perfect fit for you...
        </h2>

        <p className={css.info}>
          IMAGINE...
        </p>

        <ul>
          <li className={css.info}>→ Travelling the world to do what you love</li>
          <li className={css.info}>→ Standing on stage next to your idols</li>
          <li className={css.info}>→ Building a successful career in bodybuilding</li>
          <li className={css.info}>→ Turning your passion into profit</li>
        </ul>

        <p className={css.info}>
          You may just find the pre-loved bikini of your inspirations in The Comp Closet.
        </p>

        <PrimaryButton className={css.button} onClick={() => handleClick('SearchPage')}>Shop now</PrimaryButton>
      </div>


    </div>
  );
};

export default SectionPerfectFit;
