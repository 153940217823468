import React from 'react';
import css from './SectionOurMission.module.css';

const SectionOurMission = () => {

  return (
    <div className={css.root}>
      <div className={css.wrapper}>
        <p className={css.title}>Our mission:</p>

        <p className={css.info}>
          To support your journey to the stage, your vision and your why behind competition prep, with
          <span className={css.infoHighlight}>
            {' '}pre-loved, affordable stage wear to buy, rent or sell!
          </span>
        </p>

        <p className={css.infoBold}>
          We provide female aspiring athletes with the support, guidance and a network needed to succeed on their journey to their goals
        </p>

        <p className={css.info}>
          Created by competitors, The Comp Closet exists to connect you to what you need, when you need it so that you can keep your eye on the prize.
        </p>
      </div>
    </div>
  );
};

export default SectionOurMission;
